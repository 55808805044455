import { Dispatch, SetStateAction } from "react";
import { DialogInOne } from "../../../../../components";
import { DialogInOneProps } from "../../../../../components/DialogInOne";
import { FilterTable } from "../../../../../components/Layout";
import { useTranslation } from "../../../../../hooks";
import {
  translatePrefix,
  translateKeyObj as TK
} from "../../config";
import ViewFilter from "./ViewFilter";
import ViewTable from "./ViewTable";

interface DialogCustomerBlockListProps {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  rawData?: any
  setRawData: Dispatch<SetStateAction<any>>
}

const DialogCustomerBlockList = (props: DialogCustomerBlockListProps) => {

  const { isDialogOpen, rawData, setIsDialogOpen, setRawData } = props;

  const { t } = useTranslation(translatePrefix);

  const handleOnClose = () => {
    setIsDialogOpen(false);
    setRawData(undefined);
  }

  const dialogConfig: DialogInOneProps = {
    title: t(TK.customerBlockList),
    self: {
      open: isDialogOpen,
      onClose: () => handleOnClose(),
    },
    content: <div
      style={{
        overflowY: "auto",
        maxHeight: '80vh'
      }}
    >
      <FilterTable
        divideLineBackgroundColor="#515664"
        filter={<ViewFilter customerNumber={rawData?.customerNumber} />}
        table={<ViewTable customerNumber={rawData?.customerNumber} />}
      />
    </div>,
    size: "xl",
    onConfirm: () => { },
    isConfirmHidden: true,
    onCancel: () => handleOnClose(),
  }

  return <DialogInOne {...dialogConfig} />;
}

export default DialogCustomerBlockList;