import { EnumBlockStatus, EnumBlockType, EnumCustomerBlockReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { useListMappingTransform, useTranslation } from "../../../../../../hooks";
import { translateKeyObj as TK } from '../../../config';
import { IgetAllRes } from "../dialogConfig";
import { toDisplayTime } from "../../../../../../utils/helper";
import { EnumBlockSubmitOrigin } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master/Enum/BlockSubmitOrigin";


const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);
  const { t } = useTranslation('enumConstants');


  const listMapping = (array: IgetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item: IgetAllRes) => {
      const mappedResult = [
        [TK.status, t(EnumBlockStatus[item.status])],
        [TK.customerBlockId, item.id],
        [TK.blockType, t(EnumBlockType[item.type])],
        [TK.blockReason, t(EnumCustomerBlockReason[item.customerBlockReason])],
        [TK.submitOrigin, t(EnumBlockSubmitOrigin[item.submitOrigin])],
        [TK.riskAlertId, item.riskAlertId],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.blockedBy, item.blockedBy],
        [TK.releasedBy, item.releasedBy],
        [TK.blockedTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)], 
        [TK.e6Memo, item.e6Memo],
        [TK.blockRemarks, item.remarks],
        [TK.releaseRemarks, item.releaseRemarks],
        ['rawData', item]
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });
    const output = res.map(listMappingTransform);
    return output;
  }
  return listMapping;
}

export default useListMapping;