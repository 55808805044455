import { create } from "zustand";

import {
  initAssetListContent,
  initMyYubiDetails,
  initOnPortalFields,
  initStateForEdit,
  initStateForWatch,
} from "./initialStatesFactory";
import {
  IAssetList,
  IAuditLog,
  IListFace,
  IRefetchStore,
  IShowIconStore,
  ITabsStore,
  ItabValueStore,
  ItranslationStore,
  IWithDrawApprovalSettingStore,
  IYubiKeyStore,
} from "./types";

interface IgeneralList extends IListFace {
  meta: {
    page: number;
  };
  setGeneralList: (record: IListFace) => void;
  setPage: (page: number) => void;
  clear: () => void;
}

const initZusListStore = {
  meta: { page: 0 },
  count: 0,
  rows: [],
  chain_type: "",
  chain_id: "",
};
export const useZusListStore = create<IgeneralList>((set) => ({
  ...initZusListStore,
  setGeneralList: ({ rows, count, chain_type, chain_id }: IListFace) =>
    set(() => ({ rows, count, chain_type, chain_id })),
  setPage: (page) => set(() => ({ meta: { page } })),
  clear: () => set(initZusListStore),
}));

/* usage
  import { useZusDialogStore } from "../../zustand/store";
  const zusDialog = useZusDialogStore();
  zusDialog.open("someDialog");
  zusDialog.close();
  <Dialog
    open={zusDialog.match("someDialog")}
    onClose={...}
    fullWidth={true}
  >
*/
type IzusDialogMain =
  | ""
  | "createRequestDialog"
  | "operationDialog"
  | "detailsDialog"
  | "approveProgress"
  | "approveRejectDialog"
  | "networkMessages"
  | "createProfileDialog"
  | "createCardProfileDetailsDialog"
  | "createCardProfileEditDialog"
  | "cardProfileDetailsDialog"
  | "editCardProfileDialog"
  | "customerReleaseCardBlockDialog";

type IzusDialogExtra =
  | ""
  | "rejectDialog"
  | "reConfirmDialog"
  | "loadingDialog"
  | "remarkDialog"
  | "networkMessages";

type Imeta = Record<string, any>;

export const useZusDialogStore = create<{
  main: IzusDialogMain;
  extra: IzusDialogExtra;
  meta: Imeta;
  extraMeta: Imeta;
  open: (name: IzusDialogMain, meta?: Imeta) => void;
  match: (name: IzusDialogMain) => boolean;
  close: () => void;
  setMeta: (meta?: Imeta) => void;
  openExtra: (name: IzusDialogExtra, extraMeta?: Imeta) => void;
  matchExtra: (name: IzusDialogExtra) => boolean;
  closeExtra: () => void;
  closeAll: () => void;
}>((set, get) => ({
  main: "",
  extra: "",
  meta: {},
  extraMeta: {},
  open: (name, meta = {}) => set(() => ({ main: name, meta })),
  match: (name) => get().main === name,
  close: () => set(() => ({ main: "", meta: {} })),
  setMeta: (meta = {}) => set(() => ({ meta })),
  setExtraMeta: (extraMeta = {}) => set(() => ({ extraMeta })),
  openExtra: (name, extraMeta = {}) => set(() => ({ extra: name, extraMeta })),
  matchExtra: (name) => get().extra === name,
  closeExtra: () => set(() => ({ extra: undefined })),
  closeAll: () => set(() => ({ main: "", meta: {}, extra: undefined })),
}));
interface IZusParams<Body> {
  body: Body;
  setBody: (params: Partial<Body>) => void;
  setPage: (page: number) => void;
  refetch: () => void;
  refetchCounter: number;
  clear: () => void;
  reset: () => void;
}
export const createZusInstance = <T>(initParams: T) =>
  create<IZusParams<T>>((set) => ({
    body: initParams,
    setBody: (params) =>
      set((state) => ({ body: { ...state.body, ...params } })),
    setPage: (page) => set((state) => ({ body: { ...state.body, page } })),
    refetch: () =>
      set((state) => ({ refetchCounter: state.refetchCounter + 1 })),
    refetchCounter: 0,
    clear: () => set({ body: initParams }),
    reset: () => set({ body: initParams, refetchCounter: 0 }),
  }));

export const useWithDrawApprovalSettingStore =
  create<IWithDrawApprovalSettingStore>((set) => ({
    configTime: { time: "" },
    recordWatch: initStateForWatch,
    recordForEdit: initStateForEdit,
    totalCountForThresholdRecord: "",
    currentValues: {
      //fix
      // currentThreshold: "",
      curr_hourly_auto_approval_limit: "",
      currentRegulation: [],
      curr_mx_hourly_limit: "",
      curr_mx_addr_hrly_count: "",
      curr_mx_addr_hrly_limit: "",
    },
    setConfigTime: (time) => set(() => ({ configTime: time })),
    setCurrentValues: (payload) =>
      set((state) => ({
        ...state,
        currentValues: {
          ...state.currentValues,
          [payload.editField.field]: payload.editField.value,
        },
      })),
    setTotalCountForThresholdRecord: (total_count) =>
      set(() => ({ totalCountForThresholdRecord: total_count })),
    setRecordWatch: (record) => set(() => ({ recordWatch: record })),
    setRecordForEdit: (record) => set(() => ({ recordForEdit: record })),
    setUpdateRecordForEdit: (payload) =>
      set((state) => ({
        ...state,
        recordForEdit: {
          ...state.recordForEdit,
          rawData: {
            ...state.recordForEdit.rawData,
            [payload.editFields.field]: payload.editFields.value,
          },
        },
      })),
  }));

//{- -For MerchantOperation Only - -}
export const useZusTabsStore = create<ITabsStore>((set) => ({
  gridTabsEumn: 0,
  setGridTabsEumn: (tabIndex) =>
    set((state) => ({ ...state, gridTabsEumn: tabIndex })),
}));

export const useAuditLogStore = create<IAuditLog>((set) => ({
  onPortalDialogDiaLogFields: initOnPortalFields,
  setOnPortalDialogFields: (record) =>
    set(() => ({ onPortalDialogDiaLogFields: record })),
}));
export const useYubiKeysStoreForReg = create<IYubiKeyStore>((set) => ({
  myDetail: initMyYubiDetails,
  setYuBiDetails: (data) => set(() => ({ myDetail: data })),
}));

//AssetList
export const useAssetListStore = create<IAssetList>((set) => ({
  recordWatch: initAssetListContent,
  currentWithdrawStatus: "",
  setRecord: (data) => set(() => ({ recordWatch: data })),
  setCurrentStatus: (data) => set(() => ({ currentWithdrawStatus: data })),
  setRecordWatch: (payload) =>
    set((state) => ({
      ...state,
      recordWatch: {
        ...state.recordWatch,
        [payload.field]: payload.value,
      },
    })),
}));

export const zusRefetchStore = create<IRefetchStore>((set) => ({
  refetchBoolean: false,
  //
  rebroadcastTab: false,
  refetchWalletMgtTab: false,
  yubiKeyRegPage: false,
  withdrawSettingReordTab: false,
  refetchFunc: (payload) =>
    set((state) => ({
      ...state,
      [payload.where]: !state[payload.where as keyof typeof state],
    })),
}));
export const zusShowIconStore = create<IShowIconStore>((set) => ({
  shouldShowIcon: false,
  theSearchHash: "",
  setShowIcon: (payload) => set(() => ({ shouldShowIcon: payload })),
  setSearchHash: (payload) => set(() => ({ theSearchHash: payload })),
}));

export const useZusTranslatePrefixStore = create<ItranslationStore>((set) => ({
  translatePrefix: "noPrefix",
  setTranslatePrefix: (payload) => set(() => ({ translatePrefix: payload })),
}));
export const useZusTabValueStore = create<ItabValueStore>((set) => ({
  tabValue: "",
  setTabValue: (payload) => set(() => ({ tabValue: payload })),
}));
