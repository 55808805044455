import { useState } from "react"
import { DataGrid, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid"
import { CustomPagination, NoRowsOverlay, TableTab } from "../../../../../components/Layout"
import { Container } from "../../../../../components/MuiGenerals"
import { customSx } from "../../../../../utils/styling"
import { dataGridDefaults } from "../../../../../utils/constant"
import { ButtonMenu, LoadingDialog } from "../../../../../components"
import { useQuery } from "react-query"
import {
  translatePrefix,
  translateKeyObj as TK
} from "../../config"
import api from "../../../../../api"
import useTableColumns from "./helpers/useTableColumns"
import { useTranslation } from '../../../../../hooks';
import useListMapping from "./helpers/useListMapping"
import DialogRelease from "./DialogRelease"
import { useZusParams } from "./dialogConfig"
import { EnumBlockSubmitOrigin } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master/Enum/BlockSubmitOrigin"
import { EnumBlockStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master"

interface ViewTableProps {
  customerNumber?: string
}

const ViewTable = ({ customerNumber }: ViewTableProps) => {

  const zusParams = useZusParams();
  const { t, } = useTranslation(translatePrefix);
  const [isDialogReleaseOpen, setIsDialogReleaseOpen] = useState(false);
  const [rawData, setRawData] = useState(undefined);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      return api.Report.riskTransactionReport.getCustomerBlockList({ ...zusParams.body, customerNumber: customerNumber });
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const renderOperationsCell = (params: GridRenderCellParams<any, any, any>) => {
    const { rawData } = params.row;
    const shouldAllowRelease = rawData?.status === EnumBlockStatus.Blocked && 
      rawData.submitOrigin === EnumBlockSubmitOrigin.CreditAdminPortal

    const buttonMenuOptions = [
      {
        name: t(TK.release),
        onClickFn: () => {
          setIsDialogReleaseOpen(true);
          setRawData(params.row.rawData)
        },
        isDisabled: !shouldAllowRelease
      },
    ]
    return <ButtonMenu title={t(TK.operation)} options={buttonMenuOptions} />;
  }

  const columns = useTableColumns({ renderOperationsCell });

  const listTableMapping = useListMapping("table");

  const content: GridRowsProp = listTableMapping(rows);

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <DialogRelease
        rawData={rawData}
        isDialogOpen={isDialogReleaseOpen}
        setIsDialogOpen={setIsDialogReleaseOpen}
        setRawData={setRawData}
      />
      <TableTab>
        <DataGrid
          {...dataGridDefaults}
          rows={content}
          rowCount={count}
          columns={columns}
          page={zusParams.body.page}
          onPageChange={zusParams.setPage}
          components={{
            NoRowsOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords: count },
          }}
        />
      </TableTab>
    </Container>
  )
}
export default ViewTable