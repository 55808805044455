import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { translateKeyObj as TK } from "../config";

interface IProps {
  renderOperationsCell: (params: GridRenderCellParams<any, any, any>) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationsCell } = props;
  const columns = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationsCell,
    }),
    useGenGridCol(TK.ruleName),
    useGenGridCol(TK.ruleVersion),
    useGenGridCol(TK.outputDescription, { minWidth: 150 }),
    useGenGridCol(TK.outputValue),
    useGenGridCol(TK.transactionId),
    useGenGridCol(TK.transactionTime),
    useGenGridCol(TK.settlementTime),
    useGenGridCol(TK.programName, { minWidth: 150 }),
    useGenGridCol(TK.customerNumber),
    useGenGridCol(TK.cardNumber),
    useGenGridCol(TK.panLast4),
    useGenGridCol(TK.transactionType),
    useGenGridCol(TK.subType),
    useGenGridCol(TK.paymentMethod),
    useGenGridCol(TK.acquirerCurrency),
    useGenGridCol(TK.acquirerAmount),
    useGenGridCol(TK.authorizationCode),
    useGenGridCol(TK.onlineTransaction),
    useGenGridCol(TK.merchantCategoryCode),
    useGenGridCol(TK.cardAcceptorName),
    useGenGridCol(TK.cardAcceptorCountryCode),
    useGenGridCol(TK.cardAcceptorCity),
    useGenGridCol(TK.memo),
    useGenGridCol(TK.customerAlert),
    useGenGridCol(TK.autoCardBlock),
    useGenGridCol(TK.cardBlockId),
    useGenGridCol(TK.cardBlockCreatedBy),
    useGenGridCol(TK.cardBlockReleasedBy),
    useGenGridCol(TK.autoCustomerBlock),
    useGenGridCol(TK.customerBlockId),
    useGenGridCol(TK.customerBlockCreatedBy),
    useGenGridCol(TK.customerBlockReleasedBy),
    useGenGridCol(TK.responseTime),
    useGenGridCol(TK.responseUser),
    useGenGridCol(TK.enableCustomerReleaseCardBlock),
    useGenGridCol(TK.createdBy),
    useGenGridCol(TK.creationTime),
  ];
  return columns;
}

export default useTableColumns;