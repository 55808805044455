import React from "react";
import ConfirmDialog from "../../../../../../../components/ConfirmDialog";
import { useTranslation } from "../../../../../../../hooks";

interface IProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmUnsuspendDialog = (props: IProps) => {
  const { isOpen, onConfirm, onClose } = props;

  const { t } = useTranslation("cardList");

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      text={t("confirmUnsuspend")}
    />
  );
};

export default ConfirmUnsuspendDialog;
