import { ChangeEvent, useState } from "react"
import { Container, SelectChangeEvent } from "../../../../../components/MuiGenerals"
import CollapsibleFilters from "../../../../../features/common/filterTable/components/Filters/CollapsibleFilters"
import ToggleFilterButton from "../../../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton"
import useCollapsibleFilters from "../../../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters"
import FilterSectionActionRow from "../../../../../features/common/filterTable/layout/FilterSectionActionRow"
import { Filter } from "../../../../../features/common/filterTable/types"
import {
  translateKeyObj as TK,
} from "../../config"
import { IField, initField, ISearchParam, useZusParams } from "./dialogConfig"
import BlockStatusSingleSelection from "../../../../../features/common/filterTable/components/Filters/BlockStatusSingleSelection"
import BlockTypeSingleSelection from "../../../../../features/common/filterTable/components/Filters/BlockTypeSingleSelection"
import SubmitOriginSingleSelection from "../../../../../features/common/filterTable/components/Filters/SubmitOriginSingleSelection"
import CustomerBlockReasonSingleSelection from "../../../../../features/common/filterTable/components/Filters/CustomerBlockReasonSingleSelection"
import { EnumBlockStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master"
interface ViewFilterProps {
  customerNumber: string
}

const ViewFilter = ({ customerNumber }: ViewFilterProps) => {

  const [fields, setFields] = useState<IField>({ ...initField, status: EnumBlockStatus.Blocked.toString() });

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters({
    isDefaultExpanded: true,
  });

  const zusParams = useZusParams();

  const onChange =
    (field: keyof typeof fields) =>
      (
        e:
          | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          | SelectChangeEvent<string>
      ) => {
        setFields((fields) => ({ ...fields, [field]: e.target.value }));
      };

  const getParams: () => ISearchParam = () => {
    return {
      ...fields,
      customerNumber: customerNumber
    }
  }

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initField);
  };

  const filters: Filter[] = [
    {
      labelKey: TK.status,
      filter: <BlockStatusSingleSelection
        value={fields.status}
        onChange={onChange("status")}
        onClear={() =>
          setFields((fields) => ({ ...fields, status: "" }))
        }
      />
    },
    {
      labelKey: TK.blockType,
      filter: <BlockTypeSingleSelection
        value={fields.type}
        onChange={onChange("type")}
        onClear={() =>
          setFields((fields) => ({ ...fields, type: "" }))
        }
      />
    },
    {
      labelKey: TK.blockReason,
      filter: <CustomerBlockReasonSingleSelection
        value={fields.customerBlockReason}
        onChange={onChange("customerBlockReason")}
        onClear={() =>
          setFields((fields) => ({ ...fields, customerBlockReason: "" }))
        }
      />
    },
    {
      labelKey: TK.submitOrigin,
      filter: <SubmitOriginSingleSelection
        value={fields.submitOrigin}
        onChange={onChange("submitOrigin")}
        onClear={() =>
          setFields((fields) => ({ ...fields, submitOrigin: "" }))
        }
      />
    }
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  )
}
export default ViewFilter