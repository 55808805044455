import { AxiosResponse } from "axios";
import { DialogInOne } from "../../../../components"
import { DialogInOneProps } from "../../../../components/DialogInOne"
import { Box } from "../../../../components/MuiGenerals";
import { useAlerting, useTranslation } from "../../../../hooks";
import { useZusDialogStore } from "../../../../zustand/store";
import {
  translatePrefix,
  translateKeyObj as TK,
  useZusParams
} from '../config'

const DialogCustomerReleaseCardBlock = () => {

  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();

  const { t } = useTranslation(translatePrefix)

  const { alerting } = useAlerting();

  const {
    title,
    contentText,
    releaseCustomer
  } = zusDialog.meta as {
    title: string;
    contentText: string;
    releaseCustomer: () => Promise<AxiosResponse<any, any>>
  }

  const onConfirm = async () => {
    const res = await releaseCustomer();
    if (!res) return alerting('error', t(TK.executeFailed));
    zusParams.refetch();
    alerting('success', t(TK.executedSuccessfully));
    zusDialog.close();
  }

  const dialogConfig: DialogInOneProps = {
    title: title,
    self: {
      open: zusDialog.match("customerReleaseCardBlockDialog"),
      onClose: () => zusDialog.close()
    },
    content: <Box>{contentText}</Box>,
    onConfirm: onConfirm,
    size: "sm",
    onCancel: () => zusDialog.close()
  }

  return <DialogInOne {...dialogConfig} />
}
export default DialogCustomerReleaseCardBlock