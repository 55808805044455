import { ChangeEvent, useState } from 'react';
import { Container } from '@mui/system';
import { MpTextField, MultipleSelection, SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import {
  useGetProgram,
  useGetRule,
  useTranslation
} from '../../../hooks';
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import { EnumE6TransactionSubType, EnumE6TransactionType } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement';
import { EnumYerOrNo } from '../../../utils/constant';
import E6ProgramNameSingleSelection from '../../../features/common/filterTable/components/Filters/E6ProgramNameSingleSelection';
import ProgramNameSingleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameSingleSelection';

const availableEnumE6TransactionType = Object.fromEntries(
  Object.entries(EnumE6TransactionType).filter(
    ([key, value]) =>
      String(value) === String(EnumE6TransactionType.Withdraw) ||
      String(value) === String(EnumE6TransactionType.Purchase)
  )
);

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const ruleObj = useGetRule();
  const programObj = useGetProgram();
  const zusParams = useZusParams();
  const DateObj = {
    CreationTime: useDatePicker(),
    TransactionTime: useDatePicker(),
    ResponseTime: useDatePicker(),
  };
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters({
    isDefaultExpanded: true,
  });

  const [fields, setFields] = useState<Ifield>(initFields);

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } =
      DateObj.CreationTime;

    const { start: creationTimeFrom, end: creationTimeTo } =
      DateObj.TransactionTime;

    const { start: responseTimeFrom, end: responseTimeTo } =
      DateObj.ResponseTime;

    return {
      ...initZusParams,
      ...fields,
      createdDateFrom,
      createdDateTo,
      creationTimeFrom,
      creationTimeTo,
      responseTimeFrom,
      responseTimeTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.resetDate();
    DateObj.TransactionTime.resetDate();
    DateObj.ResponseTime.resetDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
      (
        e:
          | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          | SelectChangeEvent<string>
      ) => {
        setFields((fields) => ({ ...fields, [field]: e.target.value }));
      };

  const isSubTypeFilterDisabled = !fields.transactionType;

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.transactionTime,
      filter: <DateObj.TransactionTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.responseTime,
      filter: <DateObj.ResponseTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.ruleName,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.ruleName) })}
        value={fields.ruleName}
        onChange={onChange("ruleName")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, merchantId: "" }))
        }
        nameFn={(name) => name}
        isNoSorting
        enumData={ruleObj}
      />
    },
    {
      labelKey: TK.programName,
      filter: <ProgramNameSingleSelection
        value={fields.programName}
        onChange={onChange('programName')}
        onClear={() =>
          setFields((fields) => ({ ...fields, programName: "" }))
        } 
      />
    },
    {
      labelKey: TK.customerNumber,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.customerNumber) })}
        value={fields.customerNumber}
        onChange={onChange("customerNumber")}
      />
    },
    {
      labelKey: TK.transactionId,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.transactionId) })}
        value={fields.transactionId}
        onChange={onChange("transactionId")}
      />
    },
    {
      labelKey: TK.panLast4,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.panLast4) })}
        value={fields.panLast4}
        onChange={onChange("panLast4")}
      />
    },
    {
      labelKey: TK.transactionType,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.transactionType) })}
        value={fields.transactionType}
        onChange={(e) => {
          setFields((fields) => ({ ...fields, transactionType: e.target.value }));
        }}
        clearSelect={() => {
          setFields((fields) => ({ ...fields, transactionType: '' }));
        }
        }
        enumData={availableEnumE6TransactionType}
      />
    },
    {
      labelKey: TK.subType,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.subType) })}
        value={fields.transactionSubType}
        onChange={(e) => {
          setFields((fields) => ({ ...fields, transactionSubType: e.target.value }));
        }
        }
        clearSelect={() => {
          setFields((fields) => ({ ...fields, transactionSubType: '' }));
        }
        }
        enumData={
          isSubTypeFilterDisabled
            ? { [t("ph_transaction_sub_type")]: "" }
            : EnumE6TransactionSubType[
            Number([fields.transactionType].toString()) as EnumE6TransactionType
            ]
        }
        nameFn={isSubTypeFilterDisabled ? (name) => name : undefined}
      />
    },
    {
      labelKey: TK.paymentMethod,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.paymentMethod) })}
        value={fields.paymentMethod}
        onChange={onChange("paymentMethod")}
      />
    },
    {
      labelKey: TK.authorizationCode,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.authorizationCode) })}
        value={fields.authCode}
        onChange={onChange("authCode")}
      />
    },
    {
      labelKey: TK.customerAlert,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.customerAlert) })}
        value={fields.customerAlert}
        onChange={onChange("customerAlert")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, customerAlert: "" }))
        }
        isNoSorting={true}
        enumData={EnumYerOrNo}
      />
    },
    {
      labelKey: TK.autoCardBlock,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.autoCardBlock) })}
        value={fields.autoCardBlock}
        onChange={onChange("autoCardBlock")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, autoCardBlock: "" }))
        }
        isNoSorting={true}
        enumData={EnumYerOrNo}
      />
    },
    {
      labelKey: TK.cardBlockId,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.cardBlockId) })}
        value={fields.cardBlockId}
        onChange={onChange("cardBlockId")}
      />
    },
    {
      labelKey: TK.autoCustomerBlock,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.autoCustomerBlock) })}
        value={fields.autoCustomerBlock}
        onChange={onChange("autoCustomerBlock")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, autoCustomerBlock: "" }))
        }
        isNoSorting={true}
        enumData={EnumYerOrNo}
      />
    },
    {
      labelKey: TK.customerBlockId,
      filter: <MpTextField
        label={tc("phInputField", { fieldName: t(TK.customerBlockId) })}
        value={fields.customerBlockId}
        onChange={onChange("customerBlockId")}
      />
    },
    {
      labelKey: TK.enableCustomerReleaseCardBlock,
      filter: <SingleSelection
        label={tc("phSelection", { fieldName: t(TK.enableCustomerReleaseCardBlock) })}
        value={fields.enableCustomerRelease}
        onChange={onChange("enableCustomerRelease")}
        clearSelect={() =>
          setFields((fields) => ({ ...fields, enableCustomerRelease: "" }))
        }
        isNoSorting={true}
        enumData={EnumYerOrNo}
      />
    }
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
