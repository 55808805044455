import { DataGrid, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid"
import { CustomPagination, NoRowsOverlay, TableTab } from "../../../../../components/Layout"
import { Container } from "../../../../../components/MuiGenerals"
import { customSx } from "../../../../../utils/styling"
import { dataGridDefaults } from "../../../../../utils/constant"
import { ButtonMenu, LoadingDialog } from "../../../../../components"
import { useQuery } from "react-query"
import {
  translatePrefix,
  translateKeyObj as TK
} from "../../config"
import api from "../../../../../api"
import useTableColumns from "./helpers/useTableColumns"
import { useTranslation } from '../../../../../hooks';
import useListMapping from "./helpers/useListMapping"
import DialogRelease from "./DialogRelease"
import { useState } from "react"
import { EnumBlockStatus, EnumCardBlockReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master"
import { useZusParams } from "./dialogConfig"
import { EnumBlockSubmitOrigin } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master/Enum/BlockSubmitOrigin"

interface ViewTableProps {
  cardNumber: string
}

const ViewTable = ({
  cardNumber
}: ViewTableProps) => {

  const zusParams = useZusParams();
  const [isDialogReleaseOpen, setIsDialogReleaseOpen] = useState(false);
  const [rawData, setRawData] = useState();
  const { t } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      return api.Report.riskTransactionReport.getCardBlockList({ ...zusParams.body, cardNumber });
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const renderOperationsCell = (params: GridRenderCellParams<any, any, any>) => {
    const { rawData } = params.row;
    const shouldAllowRelease =
      rawData?.status === EnumBlockStatus.Blocked &&
      rawData?.submitOrigin === EnumBlockSubmitOrigin.CreditAdminPortal && 
      rawData?.cardBlockReason !== EnumCardBlockReason.deactivation 
    const buttonMenuOptions = [
      {
        name: t(TK.release),
        onClickFn: () => {
          setRawData(rawData)
          setIsDialogReleaseOpen(true)
        },
        isDisabled: !shouldAllowRelease
      },
    ]
    return <ButtonMenu title={t(TK.operation)} options={buttonMenuOptions} />;
  }

  const columns = useTableColumns({ renderOperationsCell });

  const listTableMapping = useListMapping("table");

  const content: GridRowsProp = listTableMapping(rows);

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <DialogRelease
        rawData={rawData}
        setRawData={setRawData}
        isDialogOpen={isDialogReleaseOpen}
        setIsDialogOpen={setIsDialogReleaseOpen}
      />
      <TableTab>
        <DataGrid
          {...dataGridDefaults}
          rows={content}
          rowCount={count}
          columns={columns}
          page={zusParams.body.page}
          onPageChange={zusParams.setPage}
          components={{
            NoRowsOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords: count },
          }}
        />
      </TableTab>
    </Container>
  )
}
export default ViewTable