import { useEffect, useState } from "react";
import api from "../../../../../api";
import { DialogInOne } from "../../../../../components";
import {
  useTranslation,
  useAlerting,
  usePermission,
} from "../../../../../hooks";
import {
  translatePrefix,
  translateKeyObj as TK,
  useZusParams,
  IsearchParam,
  initZusParams,
  useZusParamsForTokenList,
} from "../../config";
import { ICardData } from "../../types/ICard";
import Content from "./components/Content";
import { createZusInstance } from "../../../../../zustand/store";
import { Ipage } from "../../../../../api/types";

interface TokenListDialogProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  rowData: ICardData;
}

const TokenListDialog: React.FC<TokenListDialogProps> = ({
  title,
  isOpen,
  rowData,
  refreshTable,
  closeDialog,
}) => {
  const { t, tc, translate } = useTranslation(translatePrefix);

  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const zusParams = useZusParamsForTokenList();

  const handleCloseDialog = () => {
    closeDialog();
    zusParams.setPage(0);
  };

  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => handleCloseDialog(),
    },
    content: <Content rowData={rowData} />,
    onConfirm: () => {},
    onCancel: () => handleCloseDialog(),
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "lg" as any,
    dialogHeight: {
      maxHeight: "70vh",
    },
  };

  return <DialogInOne {...dialogConfig} />;
};

export default TokenListDialog;
