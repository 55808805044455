import { useListMappingTransform, useTranslation } from "../../../../hooks";
import {
  IgetAllRes,
  translateKeyObj as TK,
} from "../config";
import { toDisplayTime } from "../../../../utils/helper";
import { EnumE6TransactionType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";
import { getTransactionSubTypeTranslateKey } from "../../../../features/common/helpers/getTransactionSubTypeDisplayValue";

const useListMapping = (mode: "export" | "table") => {

  const listMappingTransform = useListMappingTransform(mode);
  const { t, tb } = useTranslation("enumConstants");

  const listMapping = (array: IgetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item: IgetAllRes) => {

      const getTransactionSubType = () => {
        if (!item.transactionSubType) { return '' };
        const transactionSubTypeTranslateKey = getTransactionSubTypeTranslateKey(
          item.transactionSubType
        );
        return t(transactionSubTypeTranslateKey);
      }

      const mappedResult = [
        [TK.ruleName, item.ruleName],
        [TK.ruleVersion, item.ruleVersion],
        [TK.outputDescription, item.outputDesc],
        [TK.outputValue, item.outputValue],
        [TK.transactionId, item.transactionId],
        [TK.transactionTime, toDisplayTime(item.creationTime ?? '')],
        [TK.settlementTime, toDisplayTime(item.settlementDate ?? '')],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.cardNumber, item.cardNumber],
        [TK.panLast4, item.panLast4],
        [TK.transactionType, item.transactionType && t(EnumE6TransactionType[item.transactionType])],
        [TK.subType, getTransactionSubType()],
        [TK.paymentMethod, item.paymentMethod],
        [TK.acquirerCurrency, item.acquirerCurrency],
        [TK.acquirerAmount, item.acquirerAmount],
        [TK.authorizationCode, item.authCode],
        [TK.onlineTransaction, tb(item.isOnline)],
        [TK.merchantCategoryCode, item.mcc],
        [TK.cardAcceptorName, item.cardAcceptorName],
        [TK.cardAcceptorCountryCode, item.cardAcceptorCountryCode],
        [TK.cardAcceptorCity, item.cardAcceptorCity],
        [TK.memo, item.memo],
        [TK.customerAlert, tb(item.customerAlert)],
        [TK.autoCardBlock, tb(item.autoCardBlock)],
        [TK.cardBlockId, item.cardBlockId],
        [TK.cardBlockCreatedBy, item.cardBlockCreatedBy],
        [TK.cardBlockReleasedBy, item.cardBlockReleaseBy],
        [TK.autoCustomerBlock, tb(item.autoCustomerBlock)],
        [TK.customerBlockId, item.customerBlockId],
        [TK.customerBlockCreatedBy, item.customerBlockCreatedBy],
        [TK.customerBlockReleasedBy, item.customerBlockReleaseBy],
        [TK.responseTime, item.responseTime],
        [TK.responseUser, item.responseUser],
        [TK.enableCustomerReleaseCardBlock, tb(item.enableCustomerRelease)],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        ["rawData", item],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default useListMapping;