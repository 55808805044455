import { EnumCardBlockReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const CardBlockReasonSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('enumConstants');

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc('blockReason') })}
      value={value || ""}
      onChange={onChange}
      clearSelect={onClear}
      nameFn={(item) => t(item)}
      enumData={EnumCardBlockReason}
    />
  );
};

export default CardBlockReasonSingleSelection;
