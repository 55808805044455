import { FeatureReports } from '@wallet-manager/pfh-node-def-types/dist/src/FeatureCode';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from "../../../zustand/store";
import { Ipage } from "../../../api/types";

const translatePrefix: Iprefix = "riskTransactionReport";

const PermissionKey = FeatureReports.RiskTransactionReport;

const translateKeyObj = {
  operation: "operation",
  ruleName: "rule_name",
  ruleVersion: "rule_version",
  outputDescription: "output_description",
  outputValue: "output_value",
  transactionId: "transaction_id",
  transactionTime: "transaction_time",
  settlementTime: "settlement_time",
  programName: "program_name",
  customerNumber: "customer_number",
  cardNumber: "card_number",
  panLast4: "pan_last_4",
  transactionType: "transaction_type",
  subType: "sub_type",
  paymentMethod: "payment_method",
  acquirerCurrency: "acquirer_currency",
  acquirerAmount: 'acquirer_amount',
  authorizationCode: "authorization_code",
  onlineTransaction: "online_transaction",
  merchantCategoryCode: "merchant_category_code",
  cardAcceptorName: "card_acceptor_name",
  cardAcceptorCountryCode: "card_acceptor_country_code",
  cardAcceptorCity: "card_acceptor_city",
  memo: "memo",
  customerAlert: "customer_alert",
  autoCardBlock: "auto_card_block",
  cardBlockId: "card_block_id",
  cardBlockCreatedBy: "card_block_created_by",
  cardBlockReleasedBy: "card_block_released_by",
  autoCustomerBlock: "auto_customer_block",
  customerBlockId: "customer_block_id",
  customerBlockCreatedBy: "customer_block_created_by",
  customerBlockReleasedBy: "customer_block_released_by",
  responseTime: "response_time",
  responseUser: "response_user",
  enableCustomerReleaseCardBlock: "enable_customer_release_card_block",
  disableCustomerReleaseCardBlock: "disable_customer_release_card_block",
  createdBy: "created_by",
  creationTime: "creation_time",
  response: "response",
  blockCard: "block_card",
  confirmBlockCard: "confirm_block_card",
  blockCustomer: "block_customer",
  customerBlockList: "customer_block_list",
  remarks: "remarks",
  cardBlockList: "card_block_list",
  phTransactionSubType: "ph_transaction_sub_type",
  confirmToEnableCustomerRelease: "confirm_to_enable_customer_release",
  confirmToDisableCustomerRelease: "confirm_to_disable_customer_release",
  confirmToBlockThisCustomer: "confirm_to_block_this_customer",
  status: "status",
  blockType: "block_type",
  releasedBy: "released_by",
  releasedTime: "released_time",
  blockReason: "block_reason",
  release: "release",
  confirmToReleaseThisCardBlock: "confirm_to_release_this_card_block",
  confirmToReleaseThisCustomerBlock: "confirm_to_release_this_customer_block",
  executedSuccessfully: "executed_successfully",
  executeFailed: "execute_failed",
  submitOrigin: "submit_origin",
  releaseRemarks: "release_remarks",
  riskAlertId: "risk_alert_id",
  blockedBy: "blocked_by",
  blockedTime: "blocked_time",
  lastModifiedTime: "last_modified_time",
  e6Memo: "e6_memo",
  blockRemarks: "block_remarks"
} as const;

interface Ifield {
  ruleName: string;
  programName: string;
  merchantId: string;
  customerNumber: string;
  transactionId: string;
  panLast4: string;
  transactionType: string;
  transactionSubType: string;
  paymentMethod: string;
  authCode: string;
  customerAlert: string;
  autoCardBlock: string;
  cardBlockId: string;
  autoCustomerBlock: string;
  customerBlockId: string;
  enableCustomerRelease: string;
}

const initFields: Ifield = {
  ruleName: '',
  programName: '',
  merchantId: '',
  customerNumber: '',
  transactionId: '',
  panLast4: '',
  transactionType: '',
  transactionSubType: '',
  paymentMethod: '',
  authCode: '',
  customerAlert: '',
  autoCardBlock: '',
  cardBlockId: '',
  autoCustomerBlock: '',
  customerBlockId: '',
  enableCustomerRelease: ''
}

interface IsearchParam {
  createdDateFrom: string
  createdDateTo: string
  creationTimeFrom: string
  creationTimeTo: string
  responseTimeFrom: string
  responseTimeTo: string
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

interface IgetAllRes {
  id: number;
  eventId: string;
  ruleName: string;
  ruleVersion?: number;
  outputDesc?: string;
  outputValue?: string;
  programName?: string;
  creationTime?: Date;
  settlementDate?: Date;
  customerNumber?: string;
  cardNumber?: string;
  panLast4?: string;
  transactionId?: string;
  currency?: string;
  amount?: number;
  acquirerAmount?: number;
  acquirerCurrency?: string;
  memo?: string;
  authCode?: string;
  cardAcceptorCity?: string;
  cardAcceptorCountryCode?: string;
  cardAcceptorName?: string;
  mcc?: string;
  isOnline?: boolean;
  paymentMethod?: string;
  walletId?: string;
  transactionType?: number;
  transactionSubType?: number;
  customerAlert?: boolean;
  autoCardBlock?: boolean;
  cardBlockId?: number;
  cardBlockCreatedBy?: string;
  cardBlockReleaseBy?: string;
  autoCustomerBlock?: boolean;
  customerBlockId?: number;
  customerBlockCreatedBy?: string;
  customerBlockReleaseBy?: string;
  responseTime?: Date;
  responseUser?: string;
  enableCustomerRelease?: boolean;
  remark?: string;
  createdBy: string;
  createdDate: Date;
  lastModifiedDate: Date;
}

export {
  translatePrefix,
  PermissionKey,
  initZusParams,
  translateKeyObj,
  initFields,
  type IgetAllRes,
  type IsearchParam,
  type Ifield,
  useZusParams,
  omitKeyObj
};