import { Dispatch, SetStateAction, useState } from "react";
import { DialogInOne, MpTextField } from "../../../../components";
import { DialogInOneProps } from "../../../../components/DialogInOne";
import { GridBox } from "../../../../components/Layout";
import { useAlerting, useTranslation } from "../../../../hooks";
import {
  translatePrefix,
  translateKeyObj as TK,
  useZusParams
} from "../config";
import ElementBox from "./ElementBox";
import api from "../../../../api";

interface DialogBlockCustomerProps {
  isDialogOpen: boolean
  rawData?: any
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  setRawData: Dispatch<SetStateAction<any>>
}

const DialogBlockCustomer = (props: DialogBlockCustomerProps) => {

  const { isDialogOpen, rawData, setIsDialogOpen, setRawData } = props;

  const { t, tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const [remarks, setRemarks] = useState("");

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [TK.programName, <ElementBox >{rawData?.programName}</ElementBox>],
    [TK.customerNumber, <ElementBox >{rawData?.customerNumber}</ElementBox>],
    [TK.remarks, <ElementBox >
      <MpTextField
        label={tc("phInputField", { fieldName: t(TK.remarks) })}
        style={{ width: "60%" }}
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
      />
    </ElementBox>],
  ]

  const handleOnConfirm = async () => {

    const res = await api.Report.riskTransactionReport.createCustomerBlock({
      customerNumber: rawData?.customerNumber,
      remarks: remarks
    })
    if(!res) return alerting('error', t(TK.executeFailed));

    alerting('success', t(TK.executedSuccessfully));
    zusParams.refetch();
    handleOnClose();
  }

  const handleOnClose = () => {
    setIsDialogOpen(false);
    setRawData(undefined);
  }

  const dialogConfig: DialogInOneProps = {
    title: t(TK.confirmToBlockThisCustomer),
    self: {
      open: isDialogOpen,
      onClose: () => handleOnClose(),
    },
    content: <GridBox
      labelElePairArr={labelElePairArr}
      columnCount={1}
      containerSx={{ fontSize: '14px' }}
    />,
    onConfirm: () => handleOnConfirm(),
    size: "sm",
    onCancel: () => handleOnClose(),
  }

  return <DialogInOne {...dialogConfig} />;
}

export default DialogBlockCustomer;