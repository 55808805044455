import { EnumBlockStatus, EnumCustomerBlockReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master"
import { Ipage } from "../../../../../api/types"
import { initZusParams } from "../../../../../utils/config"
import { createZusInstance } from "../../../../../zustand/store"

interface IField {
  status: string;
  type: string;
  customerBlockReason: string;
  submitOrigin: string;
}

const initField: IField = {
  status: '',
  type: '',
  customerBlockReason: '',
  submitOrigin: ''
}

interface ISearchParam {
  customerNumber: string
  status: string
}

interface IgetAllRes {
  id: number;
  merchantId: number;
  riskAlertId: number;
  programName: string;
  customerNumber: string;
  type: number;
  status: number;
  e6Memo: string;
  remarks: string;
  releaseRemarks: string;
  errorReason: string;
  blockedBy: string;
  releasedBy: string;
  processedBy: string;
  createdDate: Date;
  lastModifiedDate: Date;
  customerBlockReason: EnumCustomerBlockReason;
  submitOrigin: number;
}

const useZusParams = createZusInstance<Ipage & Partial<ISearchParam>>(
  { ...initZusParams, status: EnumBlockStatus.Blocked.toString() }
)

export {
  useZusParams,
  initField,
  initZusParams,
  type IField,
  type ISearchParam,
  type IgetAllRes
}