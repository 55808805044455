import { Dispatch, SetStateAction, useState } from "react";
import { DialogInOne, LoadingDialog, MpTextField } from "../../../../../components"
import { DialogInOneProps } from "../../../../../components/DialogInOne"
import { GridBox } from "../../../../../components/Layout";
import { useAlerting, useTranslation } from "../../../../../hooks";
import { useZusDialogStore } from "../../../../../zustand/store";
import {
  translatePrefix,
  translateKeyObj as TK
} from "../../config";
import ElementBox from "../ElementBox";
import api from "../../../../../api";
import { useZusParams } from "./dialogConfig";

interface DialogReleaseProps {
  rawData: any
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  setRawData: Dispatch<SetStateAction<any>>
}

const DialogRelease = (props: DialogReleaseProps) => {
  const { isDialogOpen, rawData, setIsDialogOpen, setRawData } = props;
  const { alerting } = useAlerting();
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [releaseRemarks, setreleaseRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [TK.programName, <ElementBox>{rawData?.programName}</ElementBox>],
    [TK.customerNumber, <ElementBox>{rawData?.customerNumber}</ElementBox>],
    [TK.releaseRemarks, <ElementBox >
      <MpTextField
        label={tc("phInputField", { fieldName: t(TK.releaseRemarks) })}
        style={{ width: "60%" }}
        value={releaseRemarks}
        onChange={(e) => setreleaseRemarks(e.target.value)}
      />
    </ElementBox>],
  ]

  const handleOnConfirm = async () => {
    setIsLoading(true);
    const res = await api.Report.riskTransactionReport.releaseCardBlock({
      cardNumber: rawData?.cardNumber,
      cardBlockId: rawData?.id,
      releaseRemarks: releaseRemarks
    });
    setIsLoading(false);
    if (!res) return alerting('error', t(TK.executeFailed));
    zusParams.refetch();
    alerting("success", TK.executedSuccessfully);
    handleOnClose();
  }

  const handleOnClose = () => {
    setIsDialogOpen(false)
    setRawData(undefined)
  }

  const dialogConfig: DialogInOneProps = {
    title: t(TK.confirmToReleaseThisCardBlock),
    self: {
      open: isDialogOpen,
      onClose: () => handleOnClose(),
    },
    content: <GridBox
      labelElePairArr={labelElePairArr}
      columnCount={1}
      containerSx={{ fontSize: '14px' }}
    />,
    onConfirm: () => handleOnConfirm(),
    size: "sm",
    onCancel: () => handleOnClose(),
  }

  return <>
    <LoadingDialog forceOpen={isLoading} />
    <DialogInOne {...dialogConfig} />
  </>

}
export default DialogRelease;