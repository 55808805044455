//version no
export const versionNumber = "v1.15.2";

export interface FreeObj {
  [code: string]: string;
}

export const inputCharacterMaxLength = 256;

export const logoutCodes = ["-7", "-8"];

export const enumLanguage = {
  en: "en",
  zh_cn: "zh_cn",
} as const;

export const enumTimezone = {
  timezone0: "+0",
  timezone1: "+1",
  timezone2: "+2",
  timezone3: "+3",
  timezone4: "+4",
  timezone5: "+5",
  timezone6: "+6",
  timezone7: "+7",
  timezone8: "+8",
  timezone9: "+9",
  timezone10: "+10",
  timezone11: "+11",
  timezone12: "+12",
  timezone13: "-11",
  timezone14: "-10",
  timezone15: "-9",
  timezone16: "-8",
  timezone17: "-7",
  timezone18: "-6",
  timezone19: "-5",
  timezone20: "-4",
  timezone21: "-3",
  timezone22: "-2",
  timezone23: "-1",
  // beijing:"8",
} as const;

export const enumPortalUserYubiKeyStatus = {
  Unbound: "-1",
  Pending: "0",
  Bound: "1",
} as const;

export const defaultIntervalPendingOperationsCount = "30"; //second

export const csvRecordPageSize = 500;

export const csvRecordDivisor = 100000;

export const tableConfig = {
  pageSize: 20,
  rowsPerPageOptions: [20],
  defaultWidth: 100,
  addressWidth: 250,
  dateWidth: 150,
  freeTextWidth: 200,
  addressFields: [
    "from_address",
    "to_address",
    "gether_address",
    "invoker_address",
    "hot_wallet_address",
    "wallet_address",
    "Receiving Address",
    "Invoker Address",
    "Sending Address",
    "Wallet Address",
    "tx_hash",
    "customer_no",
    "customer_number",
    "order_id",
    "orderId",
    "repayment_order_id",
    "credit_adjustment_order_id",
    "merchant_customer_reference",
    "card_number",
    "transaction_id",
    "retrieval_reference_number",
    "program_agent_id",
    "distributor_agent_id",
    "pfh_no",
  ],
  dateFields: [
    "created_date",
    "date_operated",
    "last_modified_date",
    "date_last_modified",
    "date_approved",
    "wallet_settlement_date",
    "trans_date",
    "approved_date",
    "date_created",
    "date_created",
    "trans_date",
    "mark_date",
    "date_received",
    "date_last_callback_attempt",
    "creation_time",
    "operation_time",
    "mark_date_from",
    "mark_date_to",
  ],
  freeTextFields: ["client_id", "wallet_name", "amount", "adjustment_amount"],
};

export const dataGridDefaults = {
  autoHeight: true,
  disableColumnFilter: true,
  disableSelectionOnClick: true,
  headerHeight: 45,
  rowHeight: 40,
  paginationMode: "server",
  pageSize: tableConfig.pageSize,
  rowsPerPageOptions: tableConfig.rowsPerPageOptions,
} as const;

export const documentTypeMapping = {
  IdDocument1: "front",
  IdDocument2: "back",
} as FreeObj;

export const idTypeMapping = {
  SSN: "ssn",
  SocialInsurance: "social_insurance",
  TaxId: "tax_id",
  IdentityCard: "identity_card",
  DrivingLicense: "driving_license",
  ShareCode: "share_code",
  VoterId: "voter_id",
  Passport: "passport",
  Other: "other",
} as FreeObj;

export enum EnumYesNo {
  yes = "yes",
  no = "no",
}

export enum EnumYerOrNo {
  yes = "true",
  no = "false",
}
