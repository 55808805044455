import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { LoadingDialog } from "../../../../components";
import { ExportBtn } from "../../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  SearchFirstDataGridOverlay,
  TableTab,
} from "../../../../components/Layout";
import { Container } from "../../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../../hooks";
import useCurrencyList from "../../../../hooks/useCurrencyList";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
} from "../../../../utils/helper";
import { customSx } from "../../../../utils/styling";
import {
  apiObj as api,
  IgetAllRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import { formatDateStringFromHyphenToSlash } from "../../../../utils/dateStringUtils";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;

    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Customer Spending Report (Monthly Report)`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const isSearchBtnClicked = !!zusParams.refetchCounter;

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      if (!isSearchBtnClicked) {
        return;
      }

      return api.table(zusParams.body);
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping("table");

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  useEffect(() => () => zusParams.reset(), []);

  const columns: GridColDef[] = [
    useGenGridCol(TK.month, {
      minWidth: 100,
    }),
    useGenGridCol(TK.programAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.distributorAgentId, { minWidth: 150 }),

    useGenGridCol(TK.pfhNo, { minWidth: 150 }),
    useGenGridCol(TK.customerLabel, { minWidth: 150 }),
    useGenGridCol(TK.programName, { minWidth: 170 }),
    useGenGridCol(TK.currency, {
      minWidth: 150,
    }),
    useGenGridCol(TK.repayment),
    useGenGridCol(TK.totalTransactionAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.localPayment),
    useGenGridCol(TK.localPaymentPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.localPaymentPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.localWithdraw),
    useGenGridCol(TK.localWithdrawPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.localWithdrawPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.localRefund),
    useGenGridCol(TK.localRefundPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.localRefundPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.overseasPayment),
    useGenGridCol(TK.overseasPaymentPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.overseasPaymentPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.overseasWithdraw),
    useGenGridCol(TK.overseasWithdrawPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.overseasWithdrawPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.overseasRefund),
    useGenGridCol(TK.overseasRefundPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.overseasRefundPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.totalPfhCost, { minWidth: 150 }),
    useGenGridCol(TK.totalPaMarkup, {
      minWidth: 150,
    }),
    useGenGridCol(TK.annualFee, {
      minWidth: 150,
    }),
    useGenGridCol(TK.visaRebate, { minWidth: 150 }),
    useGenGridCol(TK.paTotalRebate, { minWidth: 150 }),
  ];
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay: isSearchBtnClicked
            ? NoRowsOverlay
            : SearchFirstDataGridOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();
  const { currencyList } = useCurrencyList();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: IgetAllRes) => {
      const decimal = getDecimal(item.currency, currencyList);
      const toDisplayAmount = displayAmountCurrying(decimal);

      const mappedResult = [
        [TK.month, formatDateStringFromHyphenToSlash(item.month)],
        [TK.programAgentId, item.programAgentId],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.pfhNo, item.customerNumber],
        [TK.customerLabel, item.customerAccount.customerLabel],
        [TK.programName, item.programName],
        [TK.currency, item.currency],
        [TK.repayment, toDisplayAmount(item.repayment)],
        [
          TK.totalTransactionAmount,
          toDisplayAmount(item.totalTransactionAmount),
        ],
        [TK.localPayment, toDisplayAmount(item.localPayment)],
        [TK.localPaymentPfhCost, toDisplayAmount(item.localPaymentPfhCost)],
        [TK.localPaymentPaMarkup, toDisplayAmount(item.localPaymentPaMarkup)],
        [TK.localWithdraw, toDisplayAmount(item.localWithdraw)],
        [TK.localWithdrawPfhCost, toDisplayAmount(item.localWithdrawPfhCost)],
        [TK.localWithdrawPaMarkup, toDisplayAmount(item.localWithdrawPaMarkup)],
        [TK.localRefund, toDisplayAmount(item.localRefund)],
        [TK.localRefundPfhCost, toDisplayAmount(item.localRefundPfhCost)],
        [TK.localRefundPaMarkup, toDisplayAmount(item.localRefundPaMarkup)],
        [TK.overseasPayment, toDisplayAmount(item.overseaPayment)],
        [
          TK.overseasPaymentPfhCost,
          toDisplayAmount(item.overseaPaymentPfhCost),
        ],
        [
          TK.overseasPaymentPaMarkup,
          toDisplayAmount(item.overseaPaymentPaMarkup),
        ],
        [TK.overseasWithdraw, toDisplayAmount(item.overseaWithdraw)],
        [
          TK.overseasWithdrawPfhCost,
          toDisplayAmount(item.overseaWithdrawPfhCost),
        ],
        [
          TK.overseasWithdrawPaMarkup,
          toDisplayAmount(item.overseaWithdrawPaMarkup),
        ],
        [TK.overseasRefund, toDisplayAmount(item.overseaRefund)],
        [TK.overseasRefundPfhCost, toDisplayAmount(item.overseaRefundPfhCost)],
        [
          TK.overseasRefundPaMarkup,
          toDisplayAmount(item.overseaRefundPaMarkup),
        ],
        [TK.totalPfhCost, toDisplayAmount(item.totalPfhCost)],
        [TK.totalPaMarkup, toDisplayAmount(item.totalPaMarkup)],
        [TK.annualFee, toDisplayAmount(item.annualFee)],
        [TK.visaRebate, toDisplayAmount(item.visaRebate)],
        [TK.paTotalRebate, toDisplayAmount(item.paTotalRebate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
