import { Ipage } from "../../../../../api/types"
import { createZusInstance } from "../../../../../zustand/store"
import { initZusParams } from "../../../../../utils/config"
import { EnumBlockStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master"


interface IField {
  status: string
  type: string
  cardBlockReason: string,
  submitOrigin: string
}

const initField: IField = {
  status: '',
  type: '',
  cardBlockReason: '',
  submitOrigin: ''
}

interface ISearchParam {
  cardNumber: string
  status: string
}

interface IgetAllRes {
  id: number;
  merchantId: number;
  riskAlertId: number;
  programName: string;
  customerNumber: string;
  type: number;
  status: number;
  cardNumber: string;
  panLast4: string;
  e6Memo: string;
  remarks: string;
  releaseRemarks: string;
  customerReleasable: boolean;
  errorReason: string;
  blockedBy: string;
  releasedBy: string;
  processedBy: string;
  createdDate: Date;
  lastModifiedDate: Date;
  cardBlockReason: string;
  submitOrigin: number;
}

const useZusParams = createZusInstance<Ipage & Partial<ISearchParam>>(
  { ...initZusParams, status: EnumBlockStatus.Blocked.toString() }
)

export {
  useZusParams,
  initField,
  initZusParams,
  type IField,
  type ISearchParam,
  type IgetAllRes
}