import { Box } from "../../../../components/MuiGenerals"

interface ElementBoxProps {
  children?: React.ReactNode
}

const ElementBox = (props: ElementBoxProps) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
    >
      {props.children}
    </Box>
  )
}
export default ElementBox