import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGenGridCol } from "../../../../../../utils/ComponentHelper"
import { translateKeyObj as TK } from "../../../config";

interface IProps {
  renderOperationsCell: (params: GridRenderCellParams<any, any, any>) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationsCell } = props;
  const columns = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationsCell,
    }),
    useGenGridCol(TK.status),
    useGenGridCol(TK.cardBlockId),
    useGenGridCol(TK.blockType),
    useGenGridCol(TK.blockReason),
    useGenGridCol(TK.submitOrigin),
    useGenGridCol(TK.riskAlertId),
    useGenGridCol(TK.enableCustomerReleaseCardBlock, { minWidth: 150 }),
    useGenGridCol(TK.programName),
    useGenGridCol(TK.customerNumber),
    useGenGridCol(TK.cardNumber),
    useGenGridCol(TK.panLast4),
    useGenGridCol(TK.blockedBy),
    useGenGridCol(TK.releasedBy),
    useGenGridCol(TK.blockedTime),
    useGenGridCol(TK.lastModifiedTime),
    useGenGridCol(TK.e6Memo),
    useGenGridCol(TK.blockRemarks),
    useGenGridCol(TK.releaseRemarks),
  ];
  return columns;
}

export default useTableColumns;