import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "cardList";

const PermissionKey = FeatureCreditManagement.CardList;

interface Ifield {
  merchantId: string;
  partnerName: string;
  programName: string[];
  cardProfileName: string;
  orderId: string;
  customerNumber: string;
  originalCardNumber: string;
  cardNumber: string;
  reissueOrderId: string;

  panLast4: string;
  status: string;
  e6CardState: string;
}

const initFields: Ifield = {
  merchantId: "",
  partnerName: "",
  programName: [],
  cardProfileName: "",
  orderId: "",
  customerNumber: "",
  originalCardNumber: "",
  cardNumber: "",
  reissueOrderId: "",
  panLast4: "",
  status: "",
  e6CardState: "",
};

interface IsearchParam {
  merchantId: string;
  partnerName: string;
  programNames: string[];
  cardProfileName: string;
  orderId: string;
  customerNumbers: string[];
  cardNumber: string;
  originalCardNumber: string;
  panLast4: string;
  reissueOrderId: string;
  status: string;
  state: number | "";
  createdDateFrom: string;
  createdDateTo: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const useZusParamsForTokenList = createZusInstance<
  Ipage & Partial<IsearchParam>
>(initZusParams);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.cardList.getExport,
  table: api.CreditManagement.cardList.getAll,
});

const translateKeyObj = Object.freeze({
  operation: "operation",
  merchantName: "merchant_name",
  orderId: "order_id",
  orderSequence: "order_sequence",
  partnerName: "partner_name",
  programName: "program_name",
  cardProfileName: "card_profile_name",
  customerNo: "customer_no",
  cardNo: "card_no",
  originalCardNo: "original_card_no",
  pan: "pan",
  reissueOrderId: "reissue_order_id",
  embossedName: "embossed_name",
  cardType: "card_type",
  cardCreationTime: "card_creation_time",
  cardExpiry: "card_expiry",
  status: "status",
  createdBy: "created_by",
  creationTime: "creation_time",
  lastModifiedBy: "last_modified_by",
  lastModifiedTime: "last_modified_time",
  e6CardState: "e6_card_state",
  stateUpdatedTime: "state_updated_time",
  preCreatedCard: "pre_created_card",
  imported: "imported",
  blocked: "blocked",
  reissue: "reissue",
  deactivatedOriginalCard: "deactivated_original_card",
  deactivatedOriginalCardOnActivation:
    "deactivated_original_card_on_activation",
  cardActivationTime: "card_activation_time",
  reissueNewCard: "reissue_new_card",
  terminateCard: "terminate_card",
  TerminationDetails: "termination_details",
  confirmReissue: "confirm_reissue",
  confirmTerminate: "confirm_terminate",
  terminateSuccess: "terminate_success",
  terminateFailed: "terminate_failed",
  terminationReason: "termination_reason",
  tokenList: "token_list",
});

const omitKeyObj = Object.freeze({
  export: ["rawStatus", "cardId"],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  useZusParamsForTokenList,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
