import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import {
  CustomPagination,
  NoRowsOverlay,
  SearchFirstDataGridOverlay,
  TableTab,
} from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  usePermission,
  useTranslation
} from '../../../hooks';
import { dataGridDefaults } from '../../../utils/constant';
import useTableColumns from './helpers/useTableColumns';
import { customSx } from '../../../utils/styling';
import { LoadingDialog } from '../../../components';
import {
  omitKeyObj,
  PermissionKey,
  translatePrefix,
  useZusParams,
  translateKeyObj as TK
} from './config';
import { useQuery } from 'react-query';
import useListMapping from './helpers/useListMapping';
import api from '../../../api';
import { ButtonMenu, ExportBtn } from '../../../components/Button';
import { downloadFiles, getFullApiResponse } from '../../../utils/helper';
import { useZusDialogStore } from '../../../zustand/store';
import DialogBlockCard from './Dialogs/DialogBlockCard';
import DialogCardBlockList from './Dialogs/DialogCardBlockList';
import DialogCustomerReleaseCardBlock from './Dialogs/DialogCustomerReleaseCardBlock';
import DialogBlockCustomer from './Dialogs/DialogBlockCustomer';
import { isEmpty } from 'lodash';
import DialogCustomerBlockList from './Dialogs/DialogCustomerBlockList';

function ViewTable() {

  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const listExportMapping = useListMapping("export");
  const { hasPermission } = usePermission();
  const isSearchBtnClicked = !!zusParams.refetchCounter;

  const [isDialogBlockCardOpen, setIsDialogBlockCardOpen] = useState(false);
  const [isDialogCardBlockListOpen, setIsDialogCardBlockListOpen] = useState(false);
  const [isDialogCustomerBlockListOpen, setIsDialogCustomerBlockListOpen] = useState(false);
  const [isDialogBlockCustomerOpen, setIsDialogBlockCustomerOpen] = useState(false);
  const [rawData, setRawData] = useState(undefined)

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      if (!isSearchBtnClicked) { return; }
      return api.Report.riskTransactionReport.getAll(zusParams.body);
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.Report.riskTransactionReport.getExport({ ...zusParams.body, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;

    const omitKeys = omitKeyObj.export;
    const res = listExportMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Risk Transaction Report`, res, config);
  }

  const renderOperationsCell = (params: GridRenderCellParams<any, any, any>) => {
    const { rawData } = params.row;

    const handleEnableCustomerRelease = () => zusDialog.open("customerReleaseCardBlockDialog", {
      title: t(TK.enableCustomerReleaseCardBlock),
      contentText: t(TK.confirmToEnableCustomerRelease),
      releaseCustomer: async () => {
        return await api.Report.riskTransactionReport.updateCustomerReleasable({
          riskAlertId: rawData.id,
          enableCustomerReleaseable: true
        });
      }
    });
    const handleDisableCustomerRelease = () => zusDialog.open("customerReleaseCardBlockDialog", {
      title: t(TK.disableCustomerReleaseCardBlock),
      contentText: t(TK.confirmToDisableCustomerRelease),
      releaseCustomer: async () => {
        return await api.Report.riskTransactionReport.updateCustomerReleasable({
          riskAlertId: rawData.id,
          enableCustomerReleaseable: false
        });
      }
    });
    const buttonMenuOptions = [
      {
        name: t(TK.response),
        onClickFn: async () => {
          const res = await api.Report.riskTransactionReport.response({ riskAlertId: rawData.id });
          if (!res) return alerting('error', t(TK.executeFailed));
          zusParams.refetch();
          alerting('success', t(TK.executedSuccessfully));
        },
        noShow: !isEmpty(rawData.responseTime) || !isEmpty(rawData.responseUser)
      },
      {
        name: t(TK.blockCard),
        onClickFn: () => {
          setIsDialogBlockCardOpen(true);
          setRawData(rawData)
        },
        noShow: !isEmpty(rawData.cardBlockCreatedBy)
      },
      {
        name: t(TK.cardBlockList),
        onClickFn: () => {
          setIsDialogCardBlockListOpen(true)
          setRawData(rawData)
        }
      },
      {
        name: t(TK.blockCustomer),
        onClickFn: () => {
          setIsDialogBlockCustomerOpen(true)
          setRawData(rawData)
        },
        noShow: !isEmpty(rawData.customerBlockCreatedBy)
      },
      {
        name: t(TK.customerBlockList),
        onClickFn: () => {
          setIsDialogCustomerBlockListOpen(true);
          setRawData(rawData);
        }
      },
      {
        name: t(TK.enableCustomerReleaseCardBlock),
        onClickFn: () => handleEnableCustomerRelease(),
        noShow: rawData.enableCustomerRelease
      },
      {
        name: t(TK.disableCustomerReleaseCardBlock),
        onClickFn: () => handleDisableCustomerRelease(),
        noShow: !rawData.enableCustomerRelease
      }
    ]
    return <ButtonMenu title={t(TK.operation)} options={buttonMenuOptions} />;
  }

  const columns = useTableColumns({ renderOperationsCell });

  const omitKeys = omitKeyObj.table;

  const listTableMapping = useListMapping("table");

  const content: GridRowsProp = listTableMapping(rows, omitKeys);

  useEffect(() => () => zusParams.reset(), []);

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  const rawDataConfig = {
    rawData: rawData,
    setRawData: setRawData
  }

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <DialogBlockCard
          isDialogOpen={isDialogBlockCardOpen}
          setIsDialogOpen={setIsDialogBlockCardOpen}
          {...rawDataConfig}
        />
        <DialogCardBlockList
          isDialogOpen={isDialogCardBlockListOpen}
          setIsDialogOpen={setIsDialogCardBlockListOpen}
          {...rawDataConfig}
        />
        <DialogCustomerBlockList
          isDialogOpen={isDialogCustomerBlockListOpen}
          setIsDialogOpen={setIsDialogCustomerBlockListOpen}
          {...rawDataConfig}
        />
        <DialogBlockCustomer
          isDialogOpen={isDialogBlockCustomerOpen}
          setIsDialogOpen={setIsDialogBlockCustomerOpen}
          {...rawDataConfig}
        />
        <DialogCustomerReleaseCardBlock />
        <DataGrid
          {...dataGridDefaults}
          rows={content}
          columns={columns}
          rowCount={count}
          page={zusParams.body.page}
          onPageChange={zusParams.setPage}
          components={{
            NoRowsOverlay: isSearchBtnClicked
              ? NoRowsOverlay
              : SearchFirstDataGridOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords: count },
          }}
        />
      </TableTab>
    </Container>
  );
}

export default ViewTable;
