import { useState } from "react";
import { EnumApprovalStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { Box } from "../../../../components/MuiGenerals";
import { DialogInOne } from "../../../../components";
import Content from "./components/Content";
import ActionButtons from "./components/ActionButtons";
import ApprovalProgressDialogContent from "../../ApprovalProgress/ApprovalProgressDialog/components/Content";
import RemarkTextPromptPopup from "./components/RemarkTextPromptPopup";
import {
  useAlerting,
  usePermission,
  useTranslation,
} from "../../../../hooks";
import IApproveRejectDataProps from "../../types/IApproveRejectDataProps";


interface IProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  PermissionKey: any;
  approveFn: any;
  rejectFn: any;
  rowHeaders: string[];
  data: IApproveRejectDataProps;
}

const ApproveRejectDialog = (props: IProps) => {
  const { title, isOpen, closeDialog, data, refreshTable, PermissionKey, approveFn, rejectFn, rowHeaders } = props;
  const {
    createdBy,
    operator,
    numberOfApprover = 0,
    numberOfApproved = 0,
    approveHistory = [],
    rows
  } = data;

  const [isRemarkPromptOpen, setIsRemarkPromptOpen] = useState<boolean>(false);
  const [remarksInput, setRemarksInput] = useState<string>("");
  const [remarkPromptOperation, setRemarkPromptOperation] = useState<"approve" | "reject">("approve");

  const { t } = useTranslation("approvalProgress");
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();

  const getFirstApprover = () => {
    const sortedApprovedHistory = approveHistory
      ?.filter((item) => {
        return item.status === EnumApprovalStatus.Approved;
      })
      .sort((a, b) => a.seq - b.seq);

    return sortedApprovedHistory?.[0]?.approvedBy;
  };

  const isLastApprover = numberOfApprover - numberOfApproved === 1;
  const isSameOperator = createdBy === operator;
  const firstApprover = getFirstApprover();
  const isSameApprover = firstApprover === operator;

  const hasApprovePermission = hasPermission(PermissionKey.Approve.prefix);

  const hasRejectPermission = hasPermission(PermissionKey.Reject);

  const closeDialogAndRefresh = () => {
    closeDialog();
    refreshTable();
  };

  const checkIsCanApproveProceed = () => {
    if (!hasApprovePermission) {
      alerting("error", t("no_relevant_permission"));
      return false;
    }

    if (isSameOperator) {
      alerting("error", t("no_same_operator_creator"));
      return false;
    }

    if (isSameApprover) {
      alerting("error", t("no_same_approver"));
      return false;
    }

    return true;
  };

  const selectApprovalLevel = () => {
    if (!approveHistory || approveHistory.length === 0) return null;

    const sortedPendingApprovals = approveHistory
      ?.filter((item) => item.status === EnumApprovalStatus.Pending)
      .sort((a, b) => {
        return a.seq - b.seq;
      });

    return sortedPendingApprovals[0];
  };

  const openRemarkPrompt = (mode: "approve" | "reject") => {
    setRemarkPromptOperation(mode);
    setIsRemarkPromptOpen(true);
  };

  const closeRemarkPrompt = () => {
    setRemarksInput("");
    setIsRemarkPromptOpen(false);
  };

  const handleApproveClick = async () => {
    const isCanProceed = checkIsCanApproveProceed();

    if (!isCanProceed) return;

    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    if (!hasPermission(approvalLevel.approvalPermission)) {
      return alerting("error", t("no_relevant_permission"));
    }

    openRemarkPrompt("approve");
  };
  
  const handleRejectClick = async () => {
    if (!hasRejectPermission) {
      return alerting("error", t("no_relevant_permission"));
    }
    openRemarkPrompt("reject");
  };

  const handleConfirmApproveClick = async () => {
    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    const res = await approveFn({
      id: approvalLevel.id,
      remark: remarksInput,
    });

    if (!res) return;

    const msg = isLastApprover
      ? t("request_approved")
      : t("approve_successful");

    alerting("success", msg);
    closeRemarkPrompt();
    closeDialogAndRefresh();
  };

  const handleConfirmRejectClick = async () => {
    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    const res = await rejectFn({
      id: approvalLevel.id,
      remark: remarksInput,
    });

    if (!res) return;

    alerting("success", t("request_rejected"));
    closeRemarkPrompt();
    closeDialogAndRefresh();
  };

  const onRemarkConfirm = () => {
    if (remarkPromptOperation === "approve") {
      handleConfirmApproveClick();
    } else {
      handleConfirmRejectClick();
    }
  };

  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    content: (
      <div>
        <RemarkTextPromptPopup
          title={t("remarks")}
          isOpen={isRemarkPromptOpen}
          closePrompt={closeRemarkPrompt}
          handleConfirmClick={onRemarkConfirm}
          setRemarksInput={setRemarksInput}
          remarksInput={remarksInput}
        />
        <Box>
          <Content rows={rows} rowHeaders={rowHeaders}/>
        </Box>
        <Box style={{ marginTop: "62px" }}>
          <ApprovalProgressDialogContent
            approveHistory={approveHistory}
          />
        </Box>
      </div>
    ),
    onConfirm: () => {},
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "md"  as any,
    actionButtons: (
      <ActionButtons
        handleApproveClick={handleApproveClick}
        handleRejectClick={handleRejectClick}
      />
    ),
  };

  return <DialogInOne {...dialogConfig} />;
};

export default ApproveRejectDialog;
