import React from "react";
import DialogInOne from "../DialogInOne";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text: string;
}

const ConfirmDialog = (props: IProps) => {
  const { isOpen, onClose, onConfirm, text } = props;

  return (
    <DialogInOne
      {...{
        title: "",
        self: {
          open: isOpen,
          onClose: onClose,
        },
        content: <div>{text}</div>,
        onCancel: onClose,
        onConfirm: onConfirm,
        size: "xs",
      }}
    />
  );
};

export default ConfirmDialog;
