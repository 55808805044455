import { Dispatch, SetStateAction, useEffect } from "react";
import { DialogInOne } from "../../../../../components";
import { DialogInOneProps } from "../../../../../components/DialogInOne";
import { FilterTable } from "../../../../../components/Layout";
import { useTranslation } from "../../../../../hooks";
import {
  translatePrefix,
  translateKeyObj as TK
} from "../../config";
import ViewFilter from "./ViewFilter";
import ViewTable from "./ViewTable";
interface DialogBlockCardProps {
  isDialogOpen: boolean
  rawData?: any
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  setRawData: Dispatch<SetStateAction<any>>
}

const DialogCardBlockList = (props: DialogBlockCardProps) => {

  const { isDialogOpen, rawData, setIsDialogOpen, setRawData } = props;

  const { t } = useTranslation(translatePrefix);

  const handleOnClose = () => {
    setIsDialogOpen(false);
    setRawData(undefined)
  }

  const dialogConfig: DialogInOneProps = {
    title: t(TK.cardBlockList),
    self: {
      open: isDialogOpen,
      onClose: () => handleOnClose(),
    },
    content:
      <div
        style={{
          overflowY: "auto",
          maxHeight: '80vh'
        }}
      >
        <FilterTable
          divideLineBackgroundColor="#515664"
          filter={<ViewFilter cardNumber={rawData?.cardNumber ?? ""} />}
          table={<ViewTable cardNumber={rawData?.cardNumber ?? ""}/>}
        />
      </div>,
    size: "xl",
    onConfirm: () => { },
    isConfirmHidden: true,
    onCancel: () => handleOnClose(),
  }

  return <DialogInOne {...dialogConfig} />

}

export default DialogCardBlockList;