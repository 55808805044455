import BigNumber from "bignumber.js";
import { toDisplayTime } from "../../../../../../utils/helper";

export const toDisplayDate = (dateString?: string | Date) => {
  if (!dateString) {
    return "-";
  }
  return toDisplayTime(dateString ?? "", "yyyy-MM-dd");
};

export const getPenalizationDate = (dateString?: string) => {
  if (!dateString) {
    return "-";
  }
  const dateObj = new Date(dateString);
  return toDisplayDate(dateObj);
};

export const getDueDate = (dueDate: string | undefined) => {
  if (!dueDate) {
    return "-";
  }

  return toDisplayDate(
    BigNumber(dueDate || "")
      .minus(1000)
      .toNumber() as unknown as string
  );
};
