import { SingleSelection } from "../../../../../components";
import { useGetProgram, useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ProgramNameSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const ProgramEnum = useGetProgram();

  const { tc } = useTranslation("common");

  let selectValue = value || [];

  if (!Array.isArray(selectValue)) {
    selectValue = [value];
  }

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc("programName") })}
      value={selectValue}
      onChange={onChange}
      clearSelect={onClear}
      enumData={ProgramEnum}
      nameFn={(item) => item}
    />
  );
};

export default ProgramNameSingleSelection;
