import { EptRiskTransactionReport } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from '../axiosInstance';
import { EnumCardBlockReason } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptRiskTransactionReport.RiskTransactionReport.getAll, input);
}

async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptRiskTransactionReport.RiskTransactionReport.export, input);
}

async function getCardBlockList(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptRiskTransactionReport.CardBlockList.getAll, input);
}

async function getCustomerBlockList(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptRiskTransactionReport.CustomerBlockList.getAll, input);
}

async function response(
  params: {
    riskAlertId: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(EptRiskTransactionReport.RiskTransactionReport.response, input);
}

async function updateCustomerReleasable(
  params: {
    riskAlertId: string;
    enableCustomerReleaseable: boolean;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(EptRiskTransactionReport.RiskTransactionReport.updateCustomerReleasable, input);
}

async function createCardBlock(
  params: {
    cardNumber: string
    remarks?: string
  },
  config?: any
) {
  const input = {
    reason: EnumCardBlockReason.suspended, //Hard code as required
    ...params,
    ...config,
  };
  return await axios.post(EptRiskTransactionReport.CardBlockList.create, input);
}

async function createCustomerBlock(
  params: {
    customerNumber: string,
    remarks?: string
  },
  config?: any
) {
  const input = {
    reason: EnumCardBlockReason.fraud, //Hard code as required
    ...params,
    ...config,
  }
  return await axios.post(EptRiskTransactionReport.CustomerBlockList.create, input);
}

async function releaseCardBlock(
  params: {
    cardNumber: string
    cardBlockId: string
    releaseRemarks?: string
  },
  config?: any
) {
  const input = {
    releaseMemo: "string", //TODO remove when backend update
    ...params,
    ...config,
  }
  return await axios.post(EptRiskTransactionReport.CardBlockList.release, input);
}

async function releaseCustomerBlock(
  params: {
    customerNumber: string
    customerBlockId: string
    releaseRemarks?: string
  },
  config?: any
) {
  const input = {
    releaseMemo: "string",//TODO remove when backend update
    ...params,
    ...config,
  }
  return await axios.post(EptRiskTransactionReport.CustomerBlockList.release, input);
}

export default {
  getAll,
  getExport,
  getCardBlockList,
  getCustomerBlockList,
  response,
  updateCustomerReleasable,
  createCardBlock,
  releaseCardBlock,
  createCustomerBlock,
  releaseCustomerBlock
}